(function () {
	'use strict';

	if (window.location.href.indexOf('autorefresh') >= 0) {
		var url = new URL(window.location.href);
		var params = new URLSearchParams(url.search.slice(1));
		params.delete('autorefresh');

		var paramCount = 0;
		params.forEach(function () {
			paramCount++;
		});

		var questionmark = paramCount == 0 ? '' : '?';
		window.history.replaceState(
			{},
			'',
			`${window.location.pathname}${questionmark}${params}${window.location.hash}`
		);
	}
})();
